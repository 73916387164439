import { useTranslation } from "react-i18next";
import AdminPage from "../utils/AdminPage";
import { format_date_nohour } from "../utils/Utils";
import TodoLinkedForm from "./TodoLinkedForm";

const TodoLinkedAdmin = function ({linked_id, linked_name}) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("user.task"),
        t("op.dueOn"),
        t("op.done"),
        "options"
    ];

    return <div>

        <AdminPage
            title={t("user.tasks").charAt(0).toUpperCase() + t("user.tasks").slice(1)}
            endpoint="todo"
            CustomForm={TodoLinkedForm}
            table_titles={table_titles}
            filt1="id"
            filt2="task"
            itemsPerPage={10}
            parent_criteria={{[linked_name]: linked_id}}
            RowContent={({ elt }) => {
                return <>
                <th scope="row">{elt.id}</th>
                <td>{elt.task}</td>
                <td>{format_date_nohour(elt.dueOn)}</td>
                <td>
                    <p>{(!elt.done ? '❌' : '✔️')}</p>
                </td>
            </>
            }}
        />
    </div>
}

export default TodoLinkedAdmin;