import { useEffect, useState } from 'react';
import { EditableSubForm } from '../utils/EditableSubForm';
import { my_fetch_get, slang } from '../utils/Utils';
import { useTranslation } from 'react-i18next';

const proxyUrl = require("../../package.json").proxy;

const SaleDetailsForm = function ({ idSale }) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);

    const [productList, setProductList] = useState([]);

    const [saleDetailsRefresh,
        setSaleDetailsRefresh] = useState(true);

    const toggleSaleDetailsRefresh = function () {
        setSaleDetailsRefresh(!saleDetailsRefresh)
    }

    const endpoint = "sale_detail"

    // fetch sale_details
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + "/" + endpoint + "/" + idSale);
                const data = await response.json();

                const data_with_total = []

                // Ajout d'une colonne total
                data.forEach(elt => {
                    data_with_total.push({ ...elt, "total": elt.qty * elt.unitPrice - elt.discount })
                });

                setItems(data_with_total);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [idSale, saleDetailsRefresh])

        // fetch products

        useEffect(() => {

            (async () => {
                setProductList(await my_fetch_get("/product_for_select"))
            })();
    
        }, [])

    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    const options = [
        ["modif.", "lastModification", false, "date"],
        [slang("produit","product"), "idProduct", true, "select_int",false,productList],
        [t("sale.qty"), "qty", true, "int", true],
        [t("sale.unitPrice"), "unitPrice", true, "int"],
        [t("sale.discount"), "discount", true, "int"],
        [t("sale.totalPrice"), "total", false, "int", true],
    ]

    return <EditableSubForm endpoint={endpoint}
        items={items}
        options={options}
        parent_criteria={{ idSale: idSale }}
        toggleRefresh={toggleSaleDetailsRefresh}
    />
}

export default SaleDetailsForm;