import { useTranslation } from "react-i18next";
import OpSetForm from "./OpSetForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { slang } from "../utils/Utils";
import OperationForm from "../operations/OpCategoryForm";

const OpSetAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        "details",
        "options"
    ];

    const table_titles2 = [
        "id",
        "Code",
        t("op.fullName"),
        t("op.properties"),
        "options"
    ];


    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Groupes", "Groups")}
            endpoint="op_set"
            CustomForm={OpSetForm}
            table_titles={table_titles}
            filt1="id"
            filt2="opSetName"
            filt3="details"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.opSetName}</td>
                    <td>{elt.details}</td>
                </>
            }}
        />

        <AdminPage
            title={slang("Catégories d'opérations", "Transactions Group")}
            endpoint="op_category"
            CustomForm={OperationForm}
            table_titles={table_titles2}
            filt1="id"
            filt2="code"
            filt3="fullName"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.code}</td>
                    <td>{elt.fullName + " - " + elt.op_set}</td>
                    <td>
                        <p><small>
                            {(elt.isExpense ? '❌' : '✔️')
                                + (elt.isExpense ? slang(" Sortie", " Expense") : slang(" Income", " Entrée"))}</small></p>
                    </td>
                </>
            }}
        />
    </div>
}

export default OpSetAdmin;