import { useState } from 'react';
import { MyCheckBox, MyInput, handleSubmitGeneric } from "../utils/Utils";
import { useTranslation } from 'react-i18next';

const CustomerForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        customerName: bool_modify ? current_item.customerName : "",
        tel1: bool_modify ? current_item.tel1 : "",
        tel2: bool_modify ? current_item.tel2 : "",
        isTel1WA: bool_modify ? current_item.isTel1WA : false,
        isTel2WA: bool_modify ? current_item.isTel2WA : false,
        customerAddress: bool_modify ? current_item.customerAddress : "",
        details: bool_modify ? current_item.details : "",
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <h2 className="text-center mb-3">
            {bool_modify ? t("general.modify") + " " + current_item?.customerName + " id_" + current_item?.id : t("general.add")}
        </h2>

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e,"customer",itemToSave,current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="customerName"
                myLabel={t("general.name")}
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                maxLength={150}
                placeholder={current_item?.customerName}
            />

            <MyInput
                myName="tel1"
                myLabel="Tel1"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
                maxLength={20}
                placeholder={current_item?.tel1}
                />

            <MyCheckBox
                myName="isTel1WA"
                myLabel="Tel1 Whatsapp"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={4}
                />

            <MyInput
                myName="tel2"
                myLabel="Tel2"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
                maxLength={20}
                placeholder={current_item?.tel2}
            />

            <MyCheckBox
                myName="isTel2WA"
                myLabel="Tel2 Whatsapp"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={5}
            />

            <MyInput
                myName="customerAddress"
                myLabel={t("customer.address")}
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={6}
                placeholder={current_item?.customerAddress}
            />

            <MyInput
                myName="details"
                myLabel="details"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={7}
                placeholder={current_item?.details}
            />


            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

        
    </div>
}

export default CustomerForm;