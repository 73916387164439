import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, MyCheckBox, format_date_num_nohour } from "../utils/Utils";

const TodoLinkedForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(Object.hasOwn(current_item, "task"));
    // je ne peux plus juste tester Boolean(current_item) parce que dans ce linkForm
    // j'injecte au moins un paramètre de liaison ex: idUser

    const classicEmptyRow = {
        task: bool_modify ? current_item.task : "",
        dueOn: (!bool_modify || !current_item.dueOn)
            ? Date.now()
            : current_item.dueOn,
        done: bool_modify ? current_item.done : false,
        idUser: bool_modify ? current_item.idUser : null,
    }

    const [itemToSave, setItemToSave] = useState({
        ...classicEmptyRow,
        ...current_item,
        dueOn: format_date_num_nohour(classicEmptyRow.dueOn)
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.task}
            id={current_item?.id}
        />

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "todo", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="task"
                myLabel={t("user.task")}
                myType="Text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                placeholder={current_item?.task}
            />

            <MyInput
                myName="dueOn"
                myLabel={t("op.dueOn")}
                myType="date"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
            />

            <MyCheckBox
                myName="done"
                myLabel={t("op.done")}
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
            />

            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

        
    </div>
}

export default TodoLinkedForm;