import { useTranslation } from "react-i18next";
import TodoForm from "./TodoForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { format_date_nohour, slang } from "../utils/Utils";

const TodoAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        t("op.employee"),
        t("op.dueOn"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Tâches", "Tasks")}
            endpoint="todo"
            CustomForm={TodoForm}
            table_titles={table_titles}
            filt1="id"
            filt2="task"
            filt3="user"
            itemsPerPage={10}
            globalFilters={[
                // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                [t("general.filter") + " Not Done", "done", (elt)=> (elt === 0 || elt === null)]
            ]}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.task}</td>
                    <td>
                        {elt.user}
                        <p>{(!elt.done ? '❌' : '✔️')}</p>
                    </td>
                    <td>{format_date_nohour(elt.dueOn)}</td>
                </>
            }}
        />
    </div>
}

export default TodoAdmin;