import { useTranslation } from "react-i18next";
import CustomerForm from "./CustomerForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { GetWhatsAppIcon, isEmptyObject, slang } from "../utils/Utils";

const CustomerAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        "Tel1",
        "Tel2",
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Clients", "Customers")}
            endpoint="customer"
            CustomForm={CustomerForm}
            table_titles={table_titles}
            filt1="customerName"
            filt2="tel1"
            filt3="tel2"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.customerName}</td>
                    <td>
                        {elt.tel1}
                        &nbsp;
                        <GetWhatsAppIcon isTelWA={elt.isTel1WA} />
                    </td>
                    <td>
                        {elt.tel2}
                        &nbsp;
                        <GetWhatsAppIcon isTelWA={elt.isTel2WA} />
                    </td>
                </>
            }}
            DetailsSection={({ elt }) => {

                if (isEmptyObject(elt)) return <></>

                return <div className="fs-2 bg-light p-3">
                    <h3 className="fs-2 my-3">
                        {slang("Détails ligne ", "Row details id_") + elt.id + " _ " + elt.customerName}
                    </h3>

                    <p>
                        {t("customer.address") + ": " + elt.customerAddress}
                    </p>
                    <p>
                        {"Details: " + elt.details}
                    </p>
                </div>
            }}
        />
    </div>
}

export default CustomerAdmin;