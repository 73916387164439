import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric } from '../utils/Utils';
import SupplyForm from './SupplyForm';
import RawmSupplierForm from './Rawm_supplierForm';

const RawmForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [tab, setTab] = useState(1);

    const handleChoice = function (choice) {
        setTab(choice);
    };

    const [itemToSave, setItemToSave] = useState({
        rawName: bool_modify ? current_item.rawName : "",
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cases qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }


    return <div>

        {bool_modify &&
            <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                <button type="button" className={"btn btn-lg fs-3 mb-3 fw-bold text-bold btn-" + (tab === 1 ? "primary" : "outline-dark")} onClick={() => handleChoice(1)}>{(t("supply.rawm") + " id_" + current_item?.id).toUpperCase()}</button>

                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 2 ? "primary" : "outline-dark")} onClick={() => handleChoice(2)}>{t("supply.supply").toUpperCase()}</button>

                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 3 ? "primary" : "outline-dark")} onClick={() => handleChoice(3)}>{t("supply.supplier").toUpperCase()}</button>
            </div>
        }

        {tab === 1 && <>
            <FormTitleGeneric bool_modify={bool_modify}
                name={current_item?.rawName}
                id={current_item?.id}
            />

            <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
                onSubmit={(e) => handleSubmitGeneric(e, "raw_material", itemToSave, current_item?.id, refresh_list, bool_modify)}>

                <MyInput
                    myName="rawName"
                    myLabel={t("general.name")}
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={1}
                    maxLength={150}
                    placeholder={current_item?.rawName}
                />

                {/* Validation */}

                <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

            </form>
        </>
        }

        {(bool_modify && tab === 2) && <>
            <h2 className="display-4" style={{ marginTop: "30px" }}>{t("supply.supply")}</h2>

            <SupplyForm
                idRawMaterial={current_item?.id}
            />
        </>
        }

        {(bool_modify && tab === 3) && <>
            <h2 className="display-4" style={{ marginTop: "30px" }}>{t("supply.supplier")}</h2>

            <RawmSupplierForm
                idRaw={current_item?.id}
            />
        </>
        }
    </div>
}

export default RawmForm;