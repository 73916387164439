import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, slang, MySpinningSelectSearch, format_date_num_nohour } from "../utils/Utils";

const OpRowLinkedForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(Object.hasOwn(current_item, "opDate"));
    // je ne peux plus juste tester Boolean(current_item) parce que dans ce linkForm
    // j'injecte au moins un paramètre de liaison ex: idSale

    const classicEmptyRow = {
        opDate: (!bool_modify || !current_item.opDate)
            ? Date.now()
            : current_item.opDate,
        details: bool_modify ? current_item.details : "",
        amount: bool_modify ? current_item.amount : 0,
        idOpCategory: bool_modify ? current_item.idOpCategory : null,
        idUser: bool_modify ? current_item.idUser : null,
        idSale: bool_modify ? current_item.idSale : null,
        idSupply: bool_modify ? current_item.idSupply : null,
        idDebt: bool_modify ? current_item.idDebt : null,
    }

    const [itemToSave, setItemToSave] = useState({
        ...classicEmptyRow,
        ...current_item,
        opDate: format_date_num_nohour(classicEmptyRow.opDate)
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.details}
            id={current_item?.id}
        />

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "op_row", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="opDate"
                myLabel="date"
                myType="date"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Catégories", "Loading Categories")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idOpCategory"
                searchLabel={t("op.category")}
                searchEndPoint="op_category_for_select"
                searchIdGroup={2}
            />

            <MyInput
                myName="details"
                myLabel="details"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
                placeholder={current_item?.details}
            />

            <MyInput
                myName="amount"
                myLabel={t("op.amount")}
                myType="int"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={4}
                placeholder={current_item?.amount}
            />

            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

        
    </div>
}

export default OpRowLinkedForm;