import { useTranslation } from "react-i18next";
import AdminPage from "../utils/AdminPage";
import { digitGroup, format_date_nohour, slang } from "../utils/Utils";
import OpRowLinkedForm from "./OpRowLinkedForm";

const OpRowLinkedAdmin = function ({linked_id, linked_name}) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        "Date",
        "Details",
        t("op.amount"),
        "options"
    ];

    return <div>

        <AdminPage
            title={slang("Opérations", "Transactions")}
            endpoint="op_row"
            CustomForm={OpRowLinkedForm}
            table_titles={table_titles}
            filt1="id"
            filt2="op_code"
            filt3="details"
            itemsPerPage={10}
            parent_criteria={{[linked_name]: linked_id}}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{format_date_nohour(elt.opDate)}</td>
                    <td>{elt.details}</td>
                    <td>{digitGroup(elt.amount)}</td>
                </>
            }}
        />
    </div>
}

export default OpRowLinkedAdmin;