import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, format_date_num_nohour } from '../utils/Utils';
import OpRowLinkedAdmin from '../operations/OpRowLinkedAdmin';

const DebtForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [tab, setTab] = useState(1);

    const handleChoice = function (choice) {
        setTab(choice);
    };

    const [itemToSave, setItemToSave] = useState({
        details: bool_modify ? current_item.details : "",
        amount: bool_modify ? current_item.amount : 0,
        balance: bool_modify ? current_item.balance : 0,
        dueOn: (!bool_modify || !current_item?.dueOn)
            ? format_date_num_nohour(Date.now())
            : format_date_num_nohour(current_item.dueOn),
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <>

        {bool_modify &&
            <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                <button type="button" className={"btn btn-lg fs-3 mb-3 fw-bold text-bold btn-" + (tab === 1 ? "primary" : "outline-dark")} onClick={() => handleChoice(1)}>{(t("op.debt") + " id_" + current_item?.id).toUpperCase()}</button>

                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 2 ? "primary" : "outline-dark")} onClick={() => handleChoice(2)}>OPERATIONS</button>
            </div>
        }

        {tab === 1 && <>
            <FormTitleGeneric bool_modify={bool_modify}
                name={current_item?.details}
                id={current_item?.id}
            />

            <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
                onSubmit={(e) => handleSubmitGeneric(e, "debt", itemToSave, current_item?.id, refresh_list, bool_modify)}>

                <MyInput
                    myName="details"
                    myLabel="details"
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={1}
                    placeholder={current_item?.details}
                />

                <MyInput
                    myName="amount"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={2}
                    myLabel={t("op.amount")}
                    myType="Number"
                    func={parseInt}
                    placeholder={current_item?.amount}
                />

                <MyInput
                    myName="balance"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={3}
                    myLabel={t("op.balance")}
                    myType="Number"
                    func={parseInt}
                    placeholder={current_item?.balance}
                />

                <MyInput
                    myName="dueOn"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={4}
                    myLabel={t("op.dueOn")}
                    myType="date"
                />


                {/* Validation */}

                <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

            </form>

            
        </>
        }

        {(bool_modify && tab === 2) &&

            <OpRowLinkedAdmin linked_id={current_item?.id} linked_name="idDebt" />
        }

    </>
}

export default DebtForm;