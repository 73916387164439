import { useTranslation } from "react-i18next";
import OperationForm from "./OpCategoryForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { slang } from "../utils/Utils";

const OpCategoryAdmin = function (props) {

    const { t } = useTranslation();

    // id INT NOT NULL AUTO_INCREMENT PRIMARY KEY,
    // code VARCHAR(10),
    // fullName VARCHAR(300),
    // isExpense BOOLEAN DEFAULT false,
    // concernsSalary BOOLEAN DEFAULT false,
    // concernsSale BOOLEAN DEFAULT false,
    // concernsSupply BOOLEAN DEFAULT false

    const table_titles = [
        "id",
        "Code",
        t("op.fullName"),
        t("op.properties"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Catégories d'opérations","Transactions Group")}
            endpoint="op_category"
            CustomForm={OperationForm}
            table_titles={table_titles}
            filt1="id"
            filt2="code"
            filt3="fullName"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.code}</td>
                    <td>{elt.fullName + " - " + elt.op_set}</td>
                    <td>
                        <p><small>
                            {(elt.isExpense ? '❌' : '✔️') 
                        + (elt.isExpense ? slang(" Sortie"," Expense") : slang(" Income"," Entrée"))}</small></p>
                    </td>
                </>
            }}
        />
    </div>
}

export default OpCategoryAdmin;