import { useTranslation } from "react-i18next";
import ProductForm from "./ProductForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, slang } from "../utils/Utils";

const ProductAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        t("op.salePrice"),
        t("op.costPrice"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Produits", "Products")}
            endpoint="product"
            CustomForm={ProductForm}
            table_titles={table_titles}
            filt1="id"
            filt2="productName"
            filt3="salePrice"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.productName}</td>
                    <td className="text-center">{digitGroup(elt.salePrice)}</td>
                    <td className="text-center">{digitGroup(elt.costPrice)}</td>
                </>
            }}
        />
    </div>
}

export default ProductAdmin;