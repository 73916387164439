import { useTranslation } from "react-i18next";
import RawmForm from "./RawmForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { slang } from "../utils/Utils";

const RawmAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        t("supply.supplier") + " nb",
        "options"
    ];


    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Matériaux", "Materials")}
            endpoint="raw_material"
            CustomForm={RawmForm}
            table_titles={table_titles}
            filt1="id"
            filt2="rawName"
            itemsPerPage={10}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.rawName}</td>
                    <td className="text-center">{elt.nb_suppliers}</td>
                </>
            }}
        />
    </div>
}

export default RawmAdmin;