import { useEffect, useState } from 'react';
import { EditableSubForm } from '../utils/EditableSubForm';
import { useTranslation } from 'react-i18next';
import { my_fetch_get } from '../utils/Utils';

const RawmSupplierForm = function ({ idRaw }) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    
    const [supplierList, setSupplierList] = useState([]);

    const [supplierRawmRefresh,
        setSupplierRawmRefresh] = useState(true);

    const toggleSupplierRawmRefresh = function () {
        setSupplierRawmRefresh(!supplierRawmRefresh)
    }

    const endpoint = "raw_supplier"

    // fetch supplier_raw via le endpoint raw_supplier

    useEffect(() => {

        (async () => {
            setItems(await my_fetch_get("/" + endpoint + "/" + idRaw))
        })();

    }, [idRaw, supplierRawmRefresh])

    // fetch supplier

    useEffect(() => {

        (async () => {
            setSupplierList(await my_fetch_get("/supplier_for_select"))
        })();

    }, [])

    

    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    const options = [
        [t("supply.supplier"), "idSupplier", true, "select_int",false,supplierList],
        [t("op.recordedOn"), "recordedOn", false, "date"],
    ]

    return <EditableSubForm endpoint={endpoint}
        items={items}
        options={options}
        parent_criteria={{ idRaw: idRaw }}
        toggleRefresh={toggleSupplierRawmRefresh}
    />
}

export default RawmSupplierForm;