import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyCheckBox, MyInput, FormTitleGeneric, handleSubmitGeneric, FormTitleRelated } from '../utils/Utils';
import SupplierRawmForm from './Supplier_rawmForm';

const SupplierForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        supplierName: bool_modify ? current_item.supplierName : "",
        tel1: bool_modify ? current_item.tel1 : "",
        tel2: bool_modify ? current_item.tel2 : "",
        isTel1WA: bool_modify ? current_item.isTel1WA : false,
        isTel2WA: bool_modify ? current_item.isTel2WA : false,
        details: bool_modify ? current_item.details : "",
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.supplierName}
            id={current_item?.id}
        />

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "supplier", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="supplierName"
                myLabel={t("general.name")}
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                maxLength={150}
                placeholder={current_item?.supplierName}
            />

            <MyInput
                myName="tel1"
                myLabel="Tel1"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
                maxLength={20}
                placeholder={current_item?.tel1}
            />

            <MyCheckBox
                myName="isTel1WA"
                myLabel="Tel1 Whatsapp"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={4}
            />

            <MyInput
                myName="tel2"
                myLabel="Tel2"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
                maxLength={20}
                placeholder={current_item?.tel2}
            />

            <MyCheckBox
                myName="isTel2WA"
                myLabel="Tel2 Whatsapp"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={5}
            />

            <MyInput
                myName="details"
                myLabel="details"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={6}
                placeholder={current_item?.details}
            />


            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

        

        {bool_modify && <>
            <h2 className="display-4" style={{marginTop:"50px"}}>{t("supply.rawm")}</h2>

            <FormTitleRelated
                name={current_item?.supplierName}
                id={current_item?.id}
            />

            <SupplierRawmForm idSupplier={current_item?.id} />
        </>
        }
    </div>
}

export default SupplierForm;