import { useEffect, useState } from 'react';
import { EditableSubForm } from '../utils/EditableSubForm';
import { useTranslation } from 'react-i18next';
import { my_fetch_get, MySpinningSelectSearch, slang } from '../utils/Utils';

const SupplyForm = function ({ idRawMaterial }) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);

    const [filtSupplier, setFiltsupplier] = useState(0)

    const [suppliersList, setSuppliersList] = useState([]);

    const [supplierRawmRefresh,
        setSupplierRawmRefresh] = useState(true);

    const toggleSupplierRawmRefresh = function () {
        setSupplierRawmRefresh(!supplierRawmRefresh)
    }

    const endpoint = "supply"

    // fetch supply

    useEffect(() => {

        const fetchData = async () => {

            const data = await my_fetch_get("/" + endpoint + "/" + idRawMaterial);

            const data_with_total = []

            // Ajout d'une colonne total
            data.forEach(elt => {

                data_with_total.push({
                    ...elt,
                    "total": elt.qty * elt.unitPrice - elt.discount,
                    "balance": (elt.qty * elt.unitPrice - elt.discount) - (elt.advance ?? 0)
                })
            });

            setItems(data_with_total);
        };

        fetchData();

    }, [idRawMaterial, supplierRawmRefresh])

    // fetch suppliers

    useEffect(() => {

        (async () => {
            setSuppliersList(await my_fetch_get("/supplier_for_select"))
        })();

    }, [])



    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    const options = [
        [t("sale.qty"), "qty", true, "int"],
        [t("sale.unitPrice"), "unitPrice", true, "int"],
        [t("sale.discount"), "discount", true, "int"],
        [t("sale.totalPrice"), "total", false, "int", true],
        [t("sale.advance"), "advance", true, "int", true],
        [t("sale.balance"), "balance", false, "int", true],
        [t("supply.supplier"), "idSupplier", true, "select_int", false, suppliersList],
    ]

    return <>
        <div className="col-8 m-2">

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Liste Fournisseurs", "Loading Suppliers List")}
                searchRefreshItemToSave={(myName, myValue) => { setFiltsupplier(parseInt(myValue)) }}
                searchName="idSupplier"
                searchLabel={t("op.supplier")}
                searchEndPoint="supplier_for_select"
                searchIdGroup={1}
            />
        </div>

        <EditableSubForm endpoint={endpoint}
            items={items}
            options={options}
            parent_criteria={{ idRawMaterial: idRawMaterial }}
            toggleRefresh={toggleSupplierRawmRefresh}
            globalFilters={[
                // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                [t("general.filter") + slang(" Non Soldé", " Not settled"), "balance", (elt) => (elt !== 0)],
                [t("general.filter") + slang(" Client", " Customer") + " " + filtSupplier, "idCustomer", (elt) => (elt === filtSupplier)],
            ]}
        />
    </>
}

export default SupplyForm;