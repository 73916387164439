import { useEffect, useState } from 'react';
import { EditableSubForm } from '../utils/EditableSubForm';
import { slang } from '../utils/Utils';
import { useTranslation } from 'react-i18next';

const proxyUrl = require("../../package.json").proxy;

const SaleEventsForm = function ({ idSale }) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);

    const [saleEventsRefresh,
        setSaleEventsRefresh] = useState(true);

    const toggleSaleEventsRefresh = function () {
        setSaleEventsRefresh(!saleEventsRefresh)
    }

    const endpoint = "sale_event"

    // fetch sale_details
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + "/" + endpoint + "/" + idSale);
                const data = await response.json();

                setItems(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [idSale, saleEventsRefresh])

    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    // -- 0: modification sale 1: lancée 2: prêt 3: livré 4: récupéré

    const options = [
        ["Action", "saleAction", true, "select_int", false, [
            { id: 0, optionName: slang("En Attente", "Waiting") },
            { id: 1, optionName: slang("Lancé", "Launched") },
            { id: 2, optionName: slang("Prêt", "Ready") },
            { id: 3, optionName: slang("Livré", "Delivered") },
            { id: 4, optionName: slang("Récupéré", "Retrieved") },
        ]],
        ["details", "details", false, "date"],
        [t("op.recordedOn"), "recordedOn", false, "date"],
    ]

    return <EditableSubForm
        endpoint={endpoint}
        items={items}
        options={options}
        parent_criteria={{ idSale: idSale }}
        toggleRefresh={toggleSaleEventsRefresh}
    />
}

export default SaleEventsForm;