import { useCallback, useEffect, useState } from "react";
import { digitGroup, fcomp, format_date_nohour, handleSubmitGeneric, isEmptyObject, NbItems } from "./Utils";
import { useTranslation } from "react-i18next";
const proxyUrl = require("../../package.json").proxy;

export const EditableSubForm = function ({ endpoint, items, options, parent_criteria, toggleRefresh, globalFilters = [] }) {

    const { t } = useTranslation();

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [rowEditedStatus,
        setRowEditedStatus] = useState([]);

    const [filters, setFilters] = useState([])
    const [itemsFilt, setItemsFilt] = useState(items)

    let newItem = {};

    // options : columnName, fieldName, editable, dataType, show_Total, dataSource
    const opt = {
        columnLabel: 0,
        fieldName: 1,
        editable: 2,
        dataType: 3,
        show_Total: 4,
        dataSource: 5,
    };

    options.forEach(option => {
        newItem[option[opt.fieldName]] = null;
    });

    const clearForm = function () {

        options.forEach(option => {
            newItem[option[opt.fieldName]] = null;

            switch (option[opt.dataType]) {
                case "boolean":
                    document.getElementById(option[opt.fieldName] + "_new").checked = false;
                    break;

                case "select_int":
                    document.getElementById(option[opt.fieldName] + "_new").value = 0;
                    break;

                default:
                    document.getElementById(option[opt.fieldName] + "_new").value = "";
                    break;
            }

        });

    }

    const [elt_id, setElt_id] = useState(0); // pour savoir quel élément supprimer

    const selectElt = (id) => {

        setConfirmDelete(false)

        const newArrray = rowEditedStatus

        newArrray.push(id);

        setRowEditedStatus(newArrray);

        toggleRefresh();
    }

    const deSelectElt = (id) => {

        setConfirmDelete(false)

        const newArrray = rowEditedStatus.filter(i => i !== id)


        setRowEditedStatus(newArrray);

        toggleRefresh();

    }

    const deleteElt = () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/' + endpoint + '/' + elt_id, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    credentials: "include",
                });

                //if (response.status === 201) window.location.reload();
                if (response.status === 201) toggleRefresh();

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmDelete(false)
    }

    const deleteComposante_onclick = (id) => {
        setElt_id(id)
        setConfirmDelete(!confirmDelete)
    }

    const refresh_newItem = function (myName, myValue) {

        newItem = { ...newItem, [myName]: myValue }
    }

    const GetSimpleElement = function ({ i, item }) {

        if (item === null) {

            return <span></span>
        }

        const data_type = options[i][opt.dataType];

        switch (data_type) {
            case "date":

                return <span>
                    {format_date_nohour(item[options[i][opt.fieldName]])}
                </span>

            case "boolean":
                return <span>
                    {item[options[i][opt.fieldName]] ? 'ON' : 'OFF'}
                </span>

            case "select_int":
            
                return <select
                    className="custom-select fs-3"
                    disabled
                    defaultValue={item[options[i][opt.fieldName]]}
                >
                    <option value={-1}>{t("general.select_default")}</option>

                    {options[i][opt.dataSource].map((elt, idx) => {
                        return <option key={idx}
                            value={elt.id}
                        >
                            {elt.optionName}
                        </option>
                    })}

                </select>

            case "int":
            case "float":
                return <span>
                    {digitGroup(item[options[i][opt.fieldName]])}
                </span>

            default:
                return <span>
                    {item[options[i][opt.fieldName]]}
                </span>
        }
    }

    const GetFormElement = function ({ i, item, isNew, placeholder = "" }) {

        if ((!options[i][opt.editable])) {

            return <GetSimpleElement i={i} item={item} />
        }

        const myName = options[i][opt.fieldName];

        const data_type = options[i][opt.dataType];

        switch (data_type) {
            case "text":
                return <input
                    id={myName}
                    className="form-control fs-3"
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => {
                        refresh_newItem(myName, e.target.value)
                    }}
                />

            case "boolean":
                return <div className="form-check form-switch"> <input
                    id={myName}
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                        refresh_newItem(myName, e.target.checked)
                    }}
                /></div>

            case "int":
                return <input
                    id={myName}
                    className="form-control fs-3"
                    placeholder={placeholder}
                    type="number"
                    step={1}
                    onChange={(e) => {
                        refresh_newItem(myName, parseInt(e.target.value))
                    }}
                />

            case "float":
                return <input
                    id={myName}
                    className="form-control fs-3"
                    placeholder={placeholder}
                    type="number"
                    step={0.01}
                    onChange={(e) => {
                        refresh_newItem(myName, parseFloat(e.target.value))
                    }}
                />

            case "date":
                return <input
                    id={myName}
                    className="form-control fs-3"
                    placeholder={placeholder}
                    type="date"
                    onChange={(e) => {
                        refresh_newItem(myName, e.target.value)
                    }}
                />

            case "select_int":
                return <select
                    className="custom-select fs-3"
                    id={myName}
                    onChange={(e) => {
                        refresh_newItem(myName, parseInt(e.target.value))
                    }}
                >

                    <option value={0}>{t("general.select_default")}</option>

                    {options[i][opt.dataSource].map((elt, idx) => {
                        return <option key={idx} value={elt.id}>{elt.optionName}</option>
                    })}

                </select>

            default:
                break;
        }
    }

    const GetTitleLine = function ({ isHeader }) {

        return (isHeader ?

            <tr className="fs-3">
                {options.map((option, i) => (
                    <th key={i} scope="col" className="text-center">{option[opt.columnLabel]}</th>
                ))}

                <th key={options.length} scope="col">options</th>
            </tr>
            :
            <tr className="fs-3">
                {options.map((option, i) => (
                    <td key={i} className="text-center">{option[opt.columnLabel]}</td>
                ))}

                <td key={options.length}>options</td>
            </tr>
        )

    }

    const GetTotal = function (field_name) {
        let total = 0;

        for (let i = 0; i < itemsFilt.length; i++) {
            total += itemsFilt[i][field_name];
        }

        return digitGroup(total);
    }

    const updateFilter = function (i) {
        if (!Object.hasOwn(filters, i)) {

            setFilters({ ...filters, [i]: true })
        } else {

            setFilters({ ...filters, [i]: !filters[i] })
        }

    }

    const getDataFiltered = useCallback(function () {

        if (isEmptyObject(filters)) return

        let newData = items

        Object.keys(filters).forEach(k => {
            if (filters[k]) {
                const f = globalFilters[k]
                newData = newData.filter((d) => {

                    console.log("d[f[fcomp.field]", d[f[fcomp.field]])
                    // J'exécute ici la fonction indiquée dans le tableau de filtres passé
                    return f[fcomp.func](d[f[fcomp.field]])
                })
            }
        });

        setItemsFilt(newData)

    }, [items, filters, globalFilters])

    useEffect(() => {
        getDataFiltered()
    }, [getDataFiltered])

    // const reset_filters = function () {

    //     setFilters({})
    // }

    return <>

        <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">
            {
                globalFilters.map((filt, i) => {

                    return <button
                        key={i}
                        type="button"
                        className={"btn btn-lg fs-3 mb-3 btn-" + (filters[i] ? "success" : "outline-dark")}
                        onClick={() => updateFilter(i)}
                    >
                        {filt[fcomp.title]}
                        {filters[i] ? " ON" : ""}
                    </button>
                })
            }
        </div>

        <div className="table-responsive">
            <table className="table table-hover gx-1">

                {/* LIGNE DE TITRE */}

                <thead>
                    <GetTitleLine isHeader={true} />
                </thead>

                <tbody className="fs-2">

                    {/* LIGNES DE DONNEES */}

                    {itemsFilt.map((item, i) => (rowEditedStatus.includes(item.id)
                        ?

                        // Données Formulaire inline

                        <tr key={item.id} >

                            {/* Données */}

                            {options.map((option, i) => (
                                <td key={i}>
                                    <GetFormElement i={i} item={item} isNew={false} placeholder={item[option[opt.fieldName]]} />
                                </td>
                            ))}

                            {/* Options */}
                            <td key={"options-" + item.id} style={{ whiteSpace: "nowrap" }}>

                                <button className="btn fs-1" onClick={(e) => {

                                    let result = item;

                                    options.forEach(option => {

                                        const field_name = option[opt.fieldName]

                                        if (newItem[field_name] !== null) {

                                            result[field_name] = newItem[field_name];
                                        }
                                    });

                                    const myObject = Object.assign({}, result, parent_criteria)

                                    handleSubmitGeneric(e, endpoint, myObject, item.id, () => {
                                        toggleRefresh();
                                        deSelectElt(item.id);
                                    }, true)
                                }}>
                                    ✔️
                                </button>
                                <button className="btn fs-1" onClick={() => deSelectElt(item.id)}>
                                    ❌
                                </button>
                            </td>

                        </tr>
                        :

                        // Données cases statiques

                        <tr key={item.id}>

                            {/* Données */}

                            {options.map((option, i) => (
                                <td key={i} className={(options[i][opt.dataType] === "int" ? "text-end" : "text-center")}>
                                    <GetSimpleElement i={i} item={item} />
                                </td>
                            ))}

                            {/* Options */}
                            <td key={"options-" + item.id} style={{ whiteSpace: "nowrap" }}>
                                <button className="btn" onClick={() => {
                                    selectElt(item.id)
                                }}>
                                    <img style={{ height: "45px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </button>
                                <button className="btn" onClick={() => deleteComposante_onclick(item.id)}>
                                    <img style={{ height: "45px", width: "auto" }} src="/images/garbage-trash.svg" alt="Corbeille" />
                                </button>
                                {(confirmDelete && (item.id === elt_id)) && <button className="btn btn-danger fs-4" type="button" onClick={deleteElt}>Confirm Delete</button>}
                            </td>

                        </tr>
                    ))
                    }
                    {/* LIGNE DE GRAND TOTAL */}

                    <tr>

                        {options.map((option, i) => (option[opt.show_Total]
                            ?
                            <td key={i} className="text-end">
                                <span className="fw-bold fs-2">
                                    {GetTotal(option[opt.fieldName])}
                                </span>
                            </td>
                            :
                            <td key={i}>

                            </td>
                        ))}
                    </tr>

                    {/* LIGNE DONNANT LE NB D'ELEMENTS */}

                    <tr className="fs-3">
                        <td key="nb_elements" colSpan={options.length + 1}>
                            <br />
                            <NbItems count={itemsFilt.length} />
                            <br />
                            .
                        </td>
                    </tr>

                    {/* LIGNE D'AJOUT DE DONNEES */}

                    <GetTitleLine isHeader={false} />

                    <tr>

                        {options.map((option, i) => (
                            <td key={i} className="text-center">
                                <GetFormElement i={i} item={null} isNew={true} />
                            </td>
                        ))}

                        <td key="new_line" style={{ whiteSpace: "nowrap" }}>

                            <button className="btn fs-1" onClick={(e) => {

                                const myObject = Object.assign({}, newItem, parent_criteria)

                                handleSubmitGeneric(e, endpoint, myObject, null, toggleRefresh, false)
                            }}>
                                ✔️
                            </button>
                            <button className="btn fs-1" onClick={(clearForm)}>
                                ❌
                            </button>
                        </td>

                    </tr>

                </tbody>

            </table>
        </div>
    </>
}