import { useEffect, useState } from "react";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { my_fetch_get, slang } from "../utils/Utils";

const HomePage = function (props) {

    const { t } = useTranslation();

    const [simpleStats, setSimpleStats] = useState([0, 0, 0, 0])

    // fetch simple stats

    useEffect(() => {

        (async () => {

            let result = simpleStats
            const nb_orders = await my_fetch_get("/nb_orders")

            nb_orders.forEach(element => {
                result[element.saleStatus] = element.nb
            });

            const nb_tasks = await my_fetch_get("/nb_tasks")

            nb_tasks.forEach(element => {
                result[3] = element.nb
            });

            setSimpleStats(result)
        })();

    }, [simpleStats, props.user])

    const isLogged = Boolean(props.user?.email);

    return <div>

        <Header user={props.user} />

        < div className="position-absolute top-50 start-50 translate-middle text-center">
            <h1 className="display-3 p-3 fw-normal">{t("home.welcome")}</h1>

            <img style={{ height: "20rem", width: "auto", marginBottom: "30px" }} src="/images/logo_sapinou_1.png" alt="Logo Sapinou Style" />

            {isLogged &&
                <div className="jumbotron" style={{ marginTop: "150px" }}>
                    <h1 className="display-4">{slang("En bref", "In Short")}</h1>
                    <hr className="my-4" />
                    {
                        simpleStats.map((ss, i) => (

                            <p key={i} className="fs-2">
                                {[
                                slang("Commandes en attente", "Waiting Orders"),
                                slang("Commandes lancées", "Launched Orders"),
                                slang("Commandes prêtes", "Orders Ready"),
                                slang("Tâches inachevées", "Unfinished Tasks"),
                                ][i]}
                                &nbsp;
                                <span className={(ss > 0) ? " text-primary fw-bold" : ""}>
                                    {simpleStats[i]}
                                </span>
                            </p>
                        ))
                    }
                </div>
            }

            {!isLogged &&
                <div style={{ marginTop: "100px" }}>
                    <a className="btn btn-lg btn-warning fs-1 py-3 col-5" href="/login">Log in</a>
                </div>
            }
        </div>

    </div >
}

export default HomePage;