import { useTranslation } from "react-i18next";
import DebtForm from "./DebtForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, format_date_nohour, slang } from "../utils/Utils";

const DebtAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        "details",
        t("op.dueOn"),
        t("op.amount"),
        t("op.balance"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <AdminPage
            title={slang("Les Dettes", "Debts")}
            endpoint="debt"
            CustomForm={DebtForm}
            table_titles={table_titles}
            filt1="id"
            filt2="details"
            filt3="amount"
            itemsPerPage={10}
            globalFilters={[
                // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                [t("general.filter") + slang(" Non Soldé"," Not settled"), "balance", (elt)=> (elt !== 0)]
            ]}
            RowContent={({ elt }) => {
                return <>
                    <th scope="row">{elt.id}</th>
                    <td>{elt.details}</td>
                    <td>{format_date_nohour(elt.dueOn)}</td>
                    <td>{digitGroup(elt.amount)}</td>
                    <td>{digitGroup(elt.balance)}</td>
                </>
            }}
            LastRow={({data}) => {

                const GetTotal = function (field_name) {
                    let total = 0;

                    for (let i = 0; i < data?.length; i++) {
                        total += parseInt(data[i][field_name]);
                    }

                    return digitGroup(total);
                }

                return <tr className="fw-bold fs-2">
                    <td colSpan={3}>Grand Total</td>
                    <td>{GetTotal("amount")}</td>
                    <td>{GetTotal("balance")}</td>
                </tr>
            }
            }
        />

    </div>
}

export default DebtAdmin;