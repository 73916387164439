import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric } from '../utils/Utils';

const OpSetForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        opSetName: bool_modify ? current_item.opSetName : "",
        details: bool_modify ? current_item.details : "",
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.opSetName}
            id={current_item?.id}
        />

        <form className="col-lg-6 col-md-8 col-sm-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "op_set", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="opSetName"
                myLabel={t("general.name")}
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                maxLength={150}
                placeholder={current_item?.opSetName}
                />

            <MyInput
                myName="details"
                myLabel="details"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
                placeholder={current_item?.details}
            />


            {/* Validation */}

            <button className="btn btn-lg btn-success fs-3" type="submit">{t(bool_modify ? "general.modify" : "general.add")}</button>

        </form>

        
    </div>
}

export default OpSetForm;